<ng-container *ngIf="state$ | async as state; else eventLoader">
	<!-- Title -->
	<div mat-dialog-title class="mat-dialog-header">
		<h2 class="dialog-title">Add to Event</h2>
	</div>
	<div mat-dialog-content>
		<!-- Form -->
		<form #tastingsEventForm="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()">
			<!-- Group -->

			<mat-form-field *ngIf="(state.data && state.data.length) else noEvents">
				<mat-label>Tastings Events</mat-label>
				<mat-select formControlName="events" multiple placeholder="None" #multiSelect>
					<mat-select-trigger>
						{{ eventsControl.value?.length + ' Selected' }}
					</mat-select-trigger>
					<mat-option>
						<ngx-mat-select-search [formControl]="eventFilterCtrl"
												placeholderLabel="Find Events..."
												noEntriesFoundLabel="No matching Events found">
 						</ngx-mat-select-search>
					</mat-option>
					<mat-option *ngFor="let event of filteredEvents | async" [value]="event.key">
						<mat-icon svgIcon="common:tastings_events"></mat-icon>&nbsp;{{ event.title }}
				   </mat-option>
				</mat-select>
				<mat-hint>Select Tastings Events</mat-hint>
				<mat-error>At least one Event is required</mat-error>
			</mat-form-field>

            <ng-template #noEvents>
                <p class="text-muted">No upcoming events available.</p>
            </ng-template>
		</form>
	</div>
	<!-- Actions -->
	<pv-form-actions
        mat-dialog-actions
        [form]="tastingsEventForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
	</pv-form-actions>
</ng-container>
<ng-template #eventLoader>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>