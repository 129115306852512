import { Measurement } from './_types';

export const LIB_MEASURES_ADVANCED_CITRUS_HARVEST: Measurement[] = [

    {
        id: "citrus_grapefruit_rind_thick",
        unit: "mm",
        label: "Grapefruit Rind Thickness",
        tag: "GRT",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_citrus_rind_thick_i1",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },
    {
        id: "citrus_juice",
        unit: "%",
        label: "Juice",
        tag: "JUI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_citrus_jui_i5",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "fruit",
    },
    {
        id: "citrus_external_color",
        unit: "#",
        label: "External Colour",
        tag: "EXC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_citrus_ext_color",
        format: "##0",
        min: 1,
        max: 8,
        suggestMin: 1,
        suggestMax: 8,
        subjectId: "fruit",
    },

]