import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/';

if (environment.production) {
    enableProdMode();
}

let beamerConfig: HTMLScriptElement;
let beamerScript: HTMLScriptElement;

const injectBeamerConfig = () => {
    if (!document.getElementById('beamerConfig')) {
        beamerConfig = document.createElement('script');
        beamerConfig.id = 'beamerConfig';
        beamerConfig.text = `var beamer_config = {
            product_id : "${environment.beamer_config_key}"
        };`;
        document.body.appendChild(beamerConfig);
    }
}

const injectBeamerScript = () => {
    if (!document.getElementById('beamerScript')) {
        beamerScript = document.createElement('script');
        beamerScript.id = 'beamerScript';
        beamerScript.src = environment.beamer_src_url;
        beamerScript.defer = true
        beamerScript.type = 'text/javascript';
        document.body.appendChild(beamerScript);
    }
}

const modifyBeamerSelector = (beamerSelector: HTMLElement) => {
    if (beamerSelector) {
        beamerSelector.style.right = '24px';
        beamerSelector.style.width = '56px';
        beamerSelector.style.height = '56px';
        beamerSelector.style.display = 'none';
    }
}

/**
 * checks if the beamer selector is in the DOM and modifies it as soon as possible
 * 50ms intervals
 */
const beamerReady = setInterval(() => {
    const beamerSelector = document.getElementById('beamerSelector');

    if (beamerSelector) {
        clearInterval(beamerReady); // breaks out of the interval
        modifyBeamerSelector(beamerSelector);
    }
}, 50);

try {

    // bootstrap angular
    platformBrowserDynamic().bootstrapModule(AppModule);

    // remove splash overlay
    let splash = document.getElementById('splash_container');

    if (typeof splash.remove === 'function') {
        splash.remove();
    } else {
        splash.parentNode.removeChild(splash);
    }

    injectBeamerConfig();
    injectBeamerScript();

}catch(e){
    console.error('Application Bootstrap Error...', e);
}

