import { Protocol } from './_types';

export const LIB_PROTOCOL_ADVANCED_CITRUS_HARVEST_EVAL: Protocol = {
    id: "citrus_advanced_harvest_eval",
    title: "Citrus Advanced Harvest Evaluation",
    description: "New Citrus Harvest Protocol",
    sampleType: "harvest",
    crop: "citrus",

    chars: [
        "citrus_wind_marks",
        "citrus_hail_marks",
        "citrus_thrip_scarring",
        "citrus_snail_scarring",
        "citrus_bollworm_injury",
        "citrus_sunburn",

        "citrus_firm",

        "citrus_fruit_size",
        "citrus_fruit_size_pick",

        "citrus_color_oranges",
        "citrus_color_oranges_lvl",
        "citrus_color_soft",
        "citrus_color_soft_lvl",
        "citrus_color_yellow_grapef",
        "citrus_color_yellow_grapef_lvl",
        "citrus_color_pigmented_grapef",
        "citrus_color_pigment_grapef_lvl",
        "citrus_color_lemons",
        "citrus_color_lemons_lvl",
        "citrus_frut_flesh_color",
        "citrus_frut_flesh_intense",
        "citrus_int_color_pigment_grapef",
        "citrus_shape_type",
        "citrus_shape_fruit_base",
        "citrus_shape_symmetry",
        "citrus_shape_consistent",
        "citrus_rind_text",
        "citrus_navel_malformed_count",
        "citrus_navel_protruding_count",
        "citrus_navel_malformed",
        "citrus_navel_protruding",
        "citrus_rind_oil_cell",
        "citrus_rind_thick",
        "citrus_albedo_thick",
        "citrus_albedo_color",
        "citrus_peelability",
        "citrus_rind_oil",

        "citrus_granulation",
        "citrus_granulation_severity",
        "citrus_gumming",
        "citrus_gumming_severity",
        "citrus_core_size",
        "citrus_vesicle_size",

        "citrus_rind_disorders",
        "citrus_rind_breakdown",
        "citrus_creasing",
        "citrus_creasing_presence",
        "citrus_splitting",
        "citrus_splitting_presence",

        "citrus_disease_detected",
        "citrus_pest_detected",
        "citrus_disorder_endoxerosis",

        "citrus_ripe_stage",

        "citrus_seed",
        "citrus_seed_detection",
        "citrus_seed_size",

        "citrus_taste_sugar",
        "citrus_taste_acid",
        "citrus_taste_balance",
        "citrus_taste_aroma",
        "citrus_taste_bitterness",
        "citrus_taste_pulp_firm",
        "citrus_taste_juice",
        "citrus_taste_ragginess",

        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_color",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest",
    ],

    measures: [
        "citrus_external_color",
        "frut_length",
        "frut_weight",
        "frut_diameter",
        "citrus_juice",
        "frut_tss",
        "frut_acid_citric",
        "frut_acid",
        "frut_seed_count",
        "citrus_grapefruit_rind_thick",
    ],

    indexes: [],

    calculations: [
        //"sugar_acid_ratio_calc",
        "citric_sugar_acid_ratio_calc",
        "seed_count_percentage",
    ]
}