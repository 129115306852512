import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Snackbar } from "@core/material";
import { Select, Store } from "@ngxs/store";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { InitTastingsSampleEventForm, TastingsSampleEventFormState, TastingsSampleEventFormStateModel } from "./tastings-sample-event-form.state";
import { TastingEvent } from "@core/data/types/tastings-event";
import { takeUntil } from "rxjs/operators";

export interface TastingsSampleEventFormDialogData {
    orgKey: string;
}

@Component({
    selector: 'pv-tastings-sample-event-form-dialog',
    templateUrl: './tastings-sample-event-form.dialog.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class TastingsSampleEventFormDialog implements OnInit, OnDestroy {
    @Select(TastingsSampleEventFormState)
    state$: Observable<TastingsSampleEventFormStateModel>;

    @Select(TastingsSampleEventFormState.data)
    data$: Observable<Partial<TastingEvent[]>>

    public defaultBannerPath = "cloudinary:provar/culteva/tastings/default-banner";
    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingsSampleEventFormDialog>,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: TastingsSampleEventFormDialogData
    ) {}

    eventOptions: TastingEvent[] = [];
    eventFilterCtrl: FormControl = new FormControl('');
    filteredEvents: ReplaySubject<TastingEvent[]> = new ReplaySubject<TastingEvent[]>(1);

    eventsControl = new FormControl([], [Validators.required]);
    formGroup: FormGroup = new FormGroup({
        events: this.eventsControl,
    });

    ngOnInit(): void {
        this._store.dispatch(new InitTastingsSampleEventForm(this.data.orgKey));

        this.data$.subscribe(data => {
            if (!data || data.length === 0) return;

            this.eventOptions = JSON.parse(JSON.stringify(data));
            this.filteredEvents.next([...this.eventOptions]);
        });

        this.eventFilterCtrl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(value => {
                this.filterEventOptions()
            })
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) this.close();
        else {
            console.warn("TastingsSampleEventDialog: form invalid", this.formGroup.value);
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    close() {
        const form = this.formGroup.value;
        this._dialogRef.close(form.events)
    }

    cancel() {
        this._dialogRef.close();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    protected filterEventOptions() {
        if (!this.eventOptions) return;

        // get the search keyword
        let search = this.eventFilterCtrl.value;
        if (!search) {
          this.filteredEvents.next([...this.eventOptions]);
          return;
        }

        search = search.toLowerCase();
        // filter the events
        this.filteredEvents.next(
          this.eventOptions.filter(option => option.title.toLowerCase().indexOf(search) > -1)
        );
    }
}