import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Status, Tag, WeightedChar } from "@core/data";
import { Select, Store } from "@ngxs/store";
import { InitNewTastingCustomWeightedCharForm, InitUpdateTastingCustomWeightedCharForm, SubmitTastingCustomWeightedCharForm, TastingCustomWeightedCharFormState, TastingCustomWeightedCharFormStateModel } from "./tasting-custom-weighted-chars-form.state";
import { Observable, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Snackbar } from "@core/material";
import { takeUntil } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export interface TastingCustomWeightedCharFormDialogData {
    key?: string;
    defaults?: Partial<WeightedChar>;
    tagOptions: Tag[];
}

@Component({
    selector: 'pv-tasting-custom-weighted-chars-form-dialog',
    templateUrl: 'tasting-custom-weighted-chars-form.dialog.html',
    styleUrls: ['tasting-custom-weighted-chars-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class TastingCustomWeightedCharFormDialog implements OnInit, OnDestroy {

    @Select(TastingCustomWeightedCharFormState)
    state$: Observable<TastingCustomWeightedCharFormStateModel>;

    @Select(TastingCustomWeightedCharFormState.data)
    data$: Observable<Partial<WeightedChar>>;

    // FORM CONTROLS
    labelControl = new FormControl(null, [Validators.required]);
    descriptionControl = new FormControl(null);
    weightControl = new FormControl(null, [Validators.required]);
    tagKeysControl = new FormControl([]);
    ownerOrgKeyControl = new FormControl(null);

    formGroup: FormGroup = new FormGroup({
        label: this.labelControl,
        description: this.descriptionControl,
        weight: this.weightControl,
        tagKeys: this.tagKeysControl,
        ownerOrgKey: this.ownerOrgKeyControl,
    });

    tagKeys: string[] = [];

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingCustomWeightedCharFormDialog>,
        private _snackBar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: TastingCustomWeightedCharFormDialogData,
    ) {}

    ngOnInit(): void {
        if (this.data.key) this._store.dispatch(new InitUpdateTastingCustomWeightedCharForm(this.data.key));
        else this._store.dispatch(new InitNewTastingCustomWeightedCharForm(this.data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.data);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });

        this.tagKeysControl.valueChanges
            .subscribe(tagKeys => {
                if (tagKeys.length < this.tagKeys.length) {
                    this.tagKeys = this.tagKeys.filter(key => tagKeys.includes(key))
                }

                if (tagKeys.length > this.tagKeys.length) {
                    let newItems = tagKeys.filter(key => !this.tagKeys.includes(key))
                    this.tagKeys.push(...newItems);
                }
            })
    }

    reset(model: Partial<WeightedChar>): void {
        let data = {
            label: model.label || null,
            description: model.description || null,
            weight: model.weight || null,
            tagKeys: model.tag_keys || [],
            ownerOrgKey: model.ownerOrgKey,
        }

        this.formGroup.reset(data);
        this.tagKeys = [...data.tagKeys];
    }

    cancel(): void {
        this._dialogRef.close();
    }

    attempt(): void {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            this._snackBar.error('Invalid Input. Check your input and try again.');
        }
    }

    save(): void {
        const form = this.formGroup.value;

        const data: Partial<WeightedChar> = {
            label: form.label,
            description: form.description,
            weight: form.weight,
            tag_keys: this.tagKeys,
            ownerOrgKey: form.ownerOrgKey,
        };

        this._store.dispatch(new SubmitTastingCustomWeightedCharForm(data));
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.tagKeys, event.previousIndex, event.currentIndex);

        this.formGroup.patchValue({
            tagKeys: this.tagKeys
        })
    }

    private getPrettyTagLabels(tagKey: string): string {
        let tag = this.data.tagOptions.find(tag => tag.key === tagKey);
        return tag.label ? tag.label : 'Unknown Characteristic';
    }
}